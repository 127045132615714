.loading-block {
    height: 100vh;
    width: 100vw;
    background: rgb(0, 0, 0, 0.7);
    z-index: 10000;
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
}

.loading-block img {
    width: 250px;
    margin: auto;
    animation: loading 2s linear infinite;

}

@keyframes loading {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}