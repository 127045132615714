.modalBody {
    background: #252525;
    padding: 20px 30px;
    border-radius: 20px;
}
.modalBody h1 {
    color: white;
    width: 100%;
    font-weight: 600;
    text-align: left;
}

.modalBody h5{
    color: white;
    width: 100%;
    margin-bottom: 5px;
}

.modal-content {
    border-radius: 40px;
    top: 65px;
}



.imgContainer {
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
}
.imgContainer img {
    width: 85%;
    margin: 0px auto;
    text-align: center;
}
.buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 20px
}

.buttonModal {
    background: var(--color1Ink);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 125px;
    height: 45px;
    color: white;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 25px;
    border: none;
    padding: 15px;
}
.buttonModalNeg {
    background: white;
    color: var(--color1Ink);
    border: 3px solid var(--color1Ink);
}
@media (max-width:556px) {


    .modal-content {
        border-radius: 30px !important;
        margin-top: 50px;

    }
}