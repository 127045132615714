/* My css */
* {
    --bs-gutter-x: 10px !important;
}

html,
body {
    height: 100%;
}

html {
    position: fixed;
    overflow: hidden;
    height: 80% !important;
}

.show {
    z-index: 10000;
}

.sb-nav-fixed {
    overflow: unset !important;
}

.screenContainer {
    background: #f2f2f2;
    padding: 18px 30px;
    min-height: 100%;
}

.link-menu {
    margin-left: 10px;
}


* {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.text-align-start {
    text-align: start;
}

.img-menu {
    margin-right: 10px;
    width: 20px;
}

.screenContainer {
    overflow: auto;
    max-height: calc(100vh - 56px);
    min-height: calc(100vh - 56px);

}

.input-pesquisa {
    font-weight: 500 !important;
    font-size: 17px !important;
    border-radius: 25px !important;
    padding: 0.5rem 0.9rem !important;
    max-width: 40%;
    min-width: 170px;
    float: right;
    transition: 0.5s;
}

.input-pesquisa-non-focus {
    font-weight: 500 !important;
    font-size: 17px !important;
    border-radius: 25px !important;
    padding: 0.5rem 0.9rem !important;
    float: right;
    transition: 0.5s;
}

.input-pesquisa:focus {
    max-width: 65% !important;
}

.generic-button {
    width: 80px;
    height: 40px;
    border-radius: 20px;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 700;
    margin: 0px 5px;
    transition: 0.5s;
}

.generic-button:hover {
    opacity: 0.6;
}

.button-back {
    background-color: white;
    border: var(--color1Ink) solid 2px;
    color: var(--color1Ink);
}

.button-save {
    background-color: var(--color1Ink);
}

.button-delete {
    background-color: #e74646;
}

.form-control {
    height: 44px;
}

main {
    position: relative;
}

.error {
    display: inline-block;
    color: #ff6060;
    font-size: 14px;
    font-weight: 500;
}

.swal2-confirm .swal2-styled {
    border-radius: 20px !important;
}

#root {
    width: 100vw !important;
}

.swal2-height-auto {
    overflow: visible !important;
}

.row-subcontainer {
    padding-right: 0px;
    padding-left: 0px;
}

.row-container {
    justify-content: center;
    width: 100%;
}

.name-screen {
    position: relative;
    top: -7px;
    color: var(--color1Ink);
}

.name-screen-title {
    font-weight: 300;
    position: relative;
    top: 5px;
}

.bottom-edit-menu {
    z-index: 10;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background-color: white;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;

}

.edit-mode {
    padding-bottom: 100px;
}

.button-add {
    background-color: var(--color1Ink);
    height: 60px;
    width: 60px;
    right: 20px;
    bottom: 20px;
    position: fixed;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0rem 1rem rgb(0 0 0 / 30%);
    transition: 0.8s;
}


.button-add:hover {
    box-shadow: 0 0rem 1rem rgb(0 0 0 / 0%);
}

.button-add img {
    width: 30px;
    height: 30px;
    margin: 15px;
}

.button-back-list {
    background-color: white;
    border: 3px solid var(--color1Ink);
    height: 60px;
    width: 60px;
    right: 93px;
    bottom: 20px;
    position: fixed;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0rem 1rem rgb(0 0 0 / 30%);
    transition: 0.8s;
}

.button-back-list:hover {
    box-shadow: 0 0rem 3rem rgb(132 139 200 / 0%);
}

.button-back-list img {
    width: 30px;
    height: 30px;
    margin: 12px;
}

@media (max-width:576px) {
    .bottom-edit-menu {
        border-radius: 30px 30px 0px 0px;
        box-shadow: 0px -10px 30px -9px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0px -10px 30px -9px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px -10px 30px -9px rgba(0, 0, 0, 0.2);
    }

    .edit-mode {
        padding-bottom: 100px;
    }

    #layoutSidenav_content {
        background: #f2f2f2;
    }

    .name-screen img {
        width: 30px !important;
    }

    .row-subcontainer {
        padding: 0px;
        margin-top: 15px;
    }

    .row-container {
        justify-content: center;
        width: auto;
    }

    .screenContainer {
        max-height: calc(100vh - 128px);
        min-height: calc(100vh - 128px);
        padding: 18px 15px 15px 15px !important;
    }

    .name-screen {
        top: 0px;
        padding-right: 0px;
        font-size: 20px;
    }

    .name-screen-professionals {

        font-size: 23px;
    }

    .name-search-2 {
        padding: 0px;
    }

    .name-search {
        padding-left: 0px;
    }

    .name-search input {
        height: 38px;
    }
}

@media (max-width: 992px) {
    #layoutSidenav {
        height: 100%;
        position: fixed;
        width: 100vw;
    }

    .bottom-edit-menu {
        position: fixed;
    }

    .edit-mode {
        padding-bottom: 130px;
    }
}

.verified-icon {
    position: absolute;
    width: 27px;
    max-width: 30px;
    left: -5px;
    top: -7px;
}

.screenTitle {
    color: var(--color1Ink);
}

.screenSubTitle {
    color: var(--color1Ink);
}

.containerTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.containerTitle .leftTile {
    font-weight: 300;
}

.col-item {
    display: flex;
    flex-direction: column;

}

.row-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 13px;
}

.button-icon-action {
    border-radius: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-icon-action:hover {
    cursor: pointer;
    opacity: 0.5;
    transition: 0.5s;
}

.fourth {
    background-color: var(--color4Ink);

}

.second {
    background-color: var(--color2Ink);

}

.third {
    background-color: var(--color3Ink);

}

/* CARDS - 100% */

.card-list-col-12 {
    background-color: var(--color1Ink);
    border-radius: 9px;
    display: flex;
    color: white;
    padding: 10px 14px;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-left: 0px;
    position: relative;
}

.status-info {
    padding: 3px 11px;
    border-radius: 8px;
    font-size: 13px;
}

.delivering {
    background-color: #3891bb;

}

.delivered {
    background-color: #38bb48;
}

.separation {
    background-color: #e5b03a;
}

.in-separation {
    background-color: #117b64;

}

.canceled {
    background-color: #cf2424;
}

.title-screen {
    color: var(--color1Ink);
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.field-datepicket {
    width: 100px;
}

.input-container {
    display: flex;
    flex-direction: column;

}

.list-fixed-header-footer {
    overflow-y: auto;
    height: calc(100vh - 210px);
    padding-right: 15px;
}

@media (max-width:625px) {
    .list-fixed-header-footer {
        height: calc(100vh - 250px);
    }
}