.login {
    height: 100vh;
    width: 100vw;
    background-color: #252525;
    ;
}
.codexLogo{
    font-size: 30px;
    color: white;
}
.login .card-header,
.login .card-body {
    background-color: #000000;
}
.login .card-header{
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
}
.login .card-body {
    border-radius: 0px 0px 10px 10px;
}
.login .card {
    border-radius: 20px;
}
.login label {
    color: white;
}

.imgLogo {
    height: 60px;
    margin: 13px;
    margin-bottom: 16px;
    margin-right: 0px;
}

.card-header {
    text-align: center;
}

.btn-login {
    padding: 8px 24px !important;
    background-color: var(--color1Ink);
    border-color: var(--color1Ink);
    font-weight: 400;
    font-size: 16px;
    border-radius: 12px;
}
.btn-login:hover{
    background-color: var(--color2Ink);

}

.inputLogin {
    background: black;
    color: white;
}

.inputLogin:focus {
    background-color: black;
    color: white;
}

footer.bg-light {
    background-color: #000000 !important;
}

footer .text-muted {
    color: white !important;
}
.error-msg{
    color: #ff3333;
    font-size: 12px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}