.screenSubContainer{
    margin-bottom: 20px;
}
.list-agreements-dashboard{
    position: relative;
}
.list-agreements-dashboard .container-scroll{
    top: 0px;
    height: 400px;
}
#dashboard {
    max-height: calc(100vh - 56px);
    min-height: calc(100vh - 56px);
}
.no-sales{
    position: relative;
    top: 20vh;
}
