.card-list-shopper{
    background-color: var(--color1Ink);
    border-radius: 9px;
    height: 125px;
    display: flex;
    color: white;
    padding: 0px 10px;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-left:0px;
    position: relative;
}

.card-list-shopper:hover{
    background: var(--bs-link-hover-color);
}

.image-client{
    background-size: cover;
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

.card-list-shopper-name{
    font-size: 1.25rem;
    display: block;
    text-align: left;

}
.card-list-shopper-cnpj_cpf{
    font-size: 1rem;
    display: block;
    text-align: left;


}
.card-list-shopper-mobile{
    font-size: 1rem;
    display: block;
    text-align: left;

}
.icon-image{
    width: 20px;
    margin-top: -3px;
}
.icon-active{
    border-radius: 50%;
    position: absolute;
    top: 15px;
    left: 7px;
    width: 30px;
    height: 30px;
    padding: 3px 4px;

}
.normal {
    background-color: #43af55;
}
.suspended{
    background-color: #f58e08;
    padding: 3px 6px;
}
.pending{
    background-color: #ede600e6;

}
.canceled{
    background-color: #c73b3b;

}