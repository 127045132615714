.modalBodyRepasse {
    background-color: var(--color2Ink);
    padding: 20px 30px;
    border-radius: 20px;
}

.modalBodyRepasse h1 {
    color: white;
    width: 100%;
    font-weight: 600;
    text-align: left;
}

.modalBodyRepasse h4 {
    color: white;
    width: 100%;
    margin-bottom: 5px;
    font-weight: 900;
}

.infoModalRepasse {
    color: white;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
}

.infoModalRepasse div {
    display: flex;
    justify-content: space-between;
}

.infoModalRepasse.item {

    font-weight: 800;
}

.infoModalRepasse .warning{
    padding-top: 15px;
    color: var(--color1Ink);
    font-weight: 800;
}

.cardUser{
    width: 100%;
    border-radius: 10px;
    background-color: var(--color1Ink);
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    gap: 15px;
    padding: 4px 10px;
    margin-top: 10px;
}

.cardUser .containerImage{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        background-color: white;
        border-radius: 50%;
}