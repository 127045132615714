.card-list-agreements{
    background-color: #555555;
    border-radius: 9px;
    height: 80px;
    display: flex;
    color: white;
    padding: 15px 20px;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}
.card-list-agreements-name{
    font-size: 1.1rem;
    text-align: end;
}
.avatar-img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #a3a3a3;
    display: flex;
    justify-content: center;
}
.avatar-img img{
    width: 40px;
}
.card-list-agreements-img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
}