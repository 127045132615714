.container-item-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.item-card {
    background-color: var(--color1Ink);
    padding: 30px;
    color: white;
    border-radius: 10px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    transition: 0.5s;
}

.item-card:hover {
    /* opacity: 0.5; */
    cursor: pointer;
    background: var(--bs-link-hover-color);
    color: white;
}

.btn-generic{
    border-radius: 20px;
    padding: 7px 17px;
    background-color: var(--color1Ink);
    border: none;


}
.row-footer-report {
    color: var(--color1Ink);
    justify-content: flex-end;
    display: flex;
    font-size: 30px;
    font-weight: 700;
    position: fixed;
    bottom: 10px;
    right: 25px;

}

.row-footer-report span {
    font-weight: 300;
    margin-left: 10px;
}

.row-footer-report .col-12 {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;

}


@media (max-width:560px) {

    .row-footer-report .col-12 div {
        display: flex;
        flex-direction: column;
    }

    .row-footer-report .col-12 {
        font-size: 17px;
        text-align: center;
    }
}